import React from 'react';
// Import Libraries
import ReactDOM from 'react-dom';
// Import Main component
import CartMergedNotification from '../customer_pages/_cart-merged-notification/cart-merged-notification';

ReactDOM.render(
  <CartMergedNotification />,
  document.getElementById('cart-merged-notification'),
);

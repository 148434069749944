/* eslint-disable react/prop-types */
// Import Libraries
import React from 'react';
import ReactDOM from 'react-dom';
// Import JS Modules
import { builderDispatch, WebUI } from '../_utils/ui';
import translate from '../_utils/tools/translate';

// -----------------------------------------------------------
//  PrintsPreview
// -----------------------------------------------------------
const PrintsPreview = ({ thumbUrl }) => {
  return (
    <div className="col-6 col-sm-3 col-md-2">
      <div className="prints-stack">
        <div />
        <div />
        <div />
        <img src={thumbUrl} alt='print preview' className="img-fluid prints-thumb" />
      </div>
    </div>
  );
};

// -----------------------------------------------------------
//  PrismPreview
// -----------------------------------------------------------
const PrismPreview = ({ thumbUrl }) => {
  return (
    <div className="col-6 col-sm-3 col-md-2">
      <img src={thumbUrl} alt='prism preview' className="img-fluid prism-thumb" />
    </div>
  );
};

const PREVIEW_THUMBS_MAX = 6;

const mergeThumbnails = (printsThumbnail, prismItems) => {
  const mergedThumbnails = prismItems.map((e) => ({
    type: 'PRISM',
    thumbUrl: e.thumbnail
  }));

  if (printsThumbnail) {
    mergedThumbnails.unshift({
      type: 'PRINTS',
      thumbUrl: printsThumbnail
    });
  }

  return mergedThumbnails;
}

const stringifyPortions = (portions) => {
  switch (portions.length) {
    case 1:
      return portions[0];
    case 2:
      return portions.join(' and ');
    default:
      return `${portions.slice(0, portions.length - 2).join(', ')} and ${portions[portions.length - 1]}`;
  }
}

class CartMergedNotification extends React.Component {
  constructor() {
    super();

    this.state = {
      basketPath: '',
      loaded: false,
      $otherModals: [],
    }
  }

  UNSAFE_componentWillMount() {
    this.builderDispatchToken = builderDispatch.register(payload => {
      if (payload.type !== WebUI.LOGIN) {
        return;
      }

      this.handleLogin(
        payload.previous_basket_contents,
        payload.basketPath
      );
    });
  }

  componentDidMount() {
    const $meJQ = this.getMeAsJQuery();

    $meJQ
      .on('show.bs.modal', this.handleModalOnShow)
      .on('hide.bs.modal', this.handleModalOnHide)
      .on('hidden.bs.modal', this.handleModalOnHidden);
  }

  componentWillUnmount() {
    builderDispatch.unregister(this.builderDispatchToken);
  }

  open = () => {
    const $meJQ = this.getMeAsJQuery();
    $meJQ.modal('show');
  }

  getMeAsJQuery = () => {
    // Yes I know this sucks...
    // eslint-disable-next-line react/no-find-dom-node
    return $(ReactDOM.findDOMNode(this));
  }

  handleLogin = (previousBasketContents, basketPath) => {
    if (!previousBasketContents) {
      // if there's a previousBasketContents object then there was a cart merge.
      // if not, then there wasn't and there's nothing for us to be doing.
      return;
    }

    const {
      prints_quantity: printsQuantity,
      prism_items_summary: prismItems = [],
    } = previousBasketContents;

    if (printsQuantity || prismItems.length) {
      // show the user what was merged from the other basket
      this.setState(
        Object.assign({}, previousBasketContents, { loaded: true }),
        this.open
      );
    } else {
      // there was nothing merged, instead the earlier session has taken over.
      this.setState({
        basketPath
      });
      this.sendNotifyCartMerged();
    }
  }

  handleModalOnHidden = () => {
    this.sendNotifyCartMerged();
  }

  handleModalOnHide = () => {
    const { $otherModals } = this.state;

    $otherModals.forEach(($otherModal) => {
      $otherModal.modal('show');
    });
  }

  handleModalOnShow = () => {
    const $otherModals = [];

    $('.modal:visible').each((i, el) => {
      const $otherModal = $(el);
      $otherModals.push($otherModal);
      $otherModal.modal('hide');
    });

    this.setState({
      $otherModals
    })
  }

  sendNotifyCartMerged = () => {
    const { basketPath } = this.state;

    builderDispatch.delayedDispatch({
      type: WebUI.CART_MERGED,
      basketPath,
    });
  }

  renderBody = () => {
    const { loaded } = this.state;

    if (!loaded) {
      return (
        <div />
      )
    }

    return (
      <div>
        <p>{this.renderHeadlineMessage()}</p>
        <div className="row">{this.renderPreviews()}</div>
        <p>
          For your convenience, we have merged the contents of the old{' '}
          {translate('cart')} into the current one.{' '}
          {this.renderComponentsMessage()}
        </p>
        <p>Please review your order before proceeding to checkout.</p>
      </div>
    )
  }

  renderComponentsMessage = () => {
    const {
      prints_quantity: printsQuantity,
      prism_items_summary: prismItems,
    } = this.state;

    const portions = prismItems.map(item => `${item.quantity} ${item.title}`);

    if (printsQuantity) {
      portions.unshift(
        `${printsQuantity} ${printsQuantity === 1 ? 'print' : 'prints'}`
      );
    }

    return `${stringifyPortions(portions)} ${portions.length === 1 && printsQuantity === 1 ? 'has' : 'have'} been added to your ${translate('cart')} as a result.`;
  }

  renderHeadlineMessage = () => {
    const {
      prints_quantity: printsQuantity,
      prism_items_summary: prismItemsSummary
    } = this.state;

    const prismItemsQuantity = prismItemsSummary.length;
    const portions = [];

    if (printsQuantity) {
      portions.push(
        `${printsQuantity} ${printsQuantity === 1 ? 'print' : 'prints'}`
      );
    }

    if (prismItemsQuantity) {
      portions.push(
        `${prismItemsQuantity} ${prismItemsQuantity === 1 ? 'item' : 'items'}`
      );
    }

    return `You already had ${portions.join(' and ')} in your ${translate('cart')} from a previous visit.`;
  }

  renderPreviewItems = (thumbnails) => {
    return thumbnails.map((t, i) => {
      if (t.type === 'PRISM') {
        const key = `prism_preview_${i}`;

        return (
          <PrismPreview thumbUrl={t.thumbUrl} key={key} />
        );
      }

      const key = `prints_preview_${i}`;

      return (
        <PrintsPreview thumbUrl={t.thumbUrl} key={key} />
      );
    });
  }

  renderPreviews = () => {
    const {
      prints_thumbnail: printsThumbnail,
      prism_items_summary: prismItems,
    } = this.state;

    const mergedThumbnails = mergeThumbnails(printsThumbnail, prismItems);

    return this.renderPreviewItems(mergedThumbnails.slice(0, PREVIEW_THUMBS_MAX))
  }

  render() {
    return (
      <div className="modal fade cart-merge-notification" role="dialog" aria-hidden="true" id='merge-cart-modal'>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{translate('Cart')} updated</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderBody()}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-lg btn-primary"
                data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CartMergedNotification;
